import { useEffect, useState } from "react";
import DeleteLogo from "src/assets/Delete";
import PlusLogo from "src/assets/Plus";
import SyncDisableIcon from "src/assets/SyncDisable";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "src/assets/Close";
import CheckIcon from "src/assets/Check";
import Select from "react-select";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import {
  getWoocommerceRedirectUrl,
  getDeskeraRedirectUrl,
  setDisconnectedDeskeraAccount,
  getDeskeraUserInfo,
  setDisconnectedWoocommerceAccount,
  getDeskeraBooksAccounts,
  getDeskeraDimensions,
  setWoocommerceConfig,
  getDeskeraBooksWarehouses,
  getDeskeraProductUom,
  getDeskeraBooksTaxes,
  saveWoocomPaymentMethodNewConfig,
  saveWoocomProductAttributes,
  saveWoocommerceTwoWaySync,
  saveWoocomTaxMappings
} from "src/api";
import { useDispatch, useSelector } from "react-redux";
import Alert from "src/components/Alert";
import customSelectTheme from "src/utils/selectTheme";
import { setDeskeraInfo } from "src/redux/actions/auth";
import {
  loadConfig, setConfig, loadAccounts, setWoocomPaymentMethodConfig,
  loadPaymentAccountConfig, setPaymentMethodConfig,loadTaxMapping, setTaxMapping, setProductAttributes, loadProuductAttributes
} from "src/redux/actions/woocommerce";
import SyncIcon from "src/assets/Sync";
import { addToast } from "src/redux/actions/toasts";
import Woocommerce from "src/assets/Woocommerce";
import { DKButton } from "deskera-ui-library";

function Config() {
  const [disconnectWarningWoocommerce, setDisconnectWarningWoocommerce] = useState(false);
  const [disconnectButtonWoocommerce, setDisconnectButtonWoocommerce] = useState(true);
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [syncWarning, setSyncWarning] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const accounts = useSelector((state) => state.woocommerce.accounts);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const [deskeraDimensions, setDeskeraDimensions] = useState([]);
  const [deskeraWarehouses, setDeskeraWarehouses] = useState([]);
  const config = useSelector((state) => state.woocommerce.config);
  const [formErrors, setFormErrors] = useState({});
  const [configOpen, setConfigOpen] = useState(false);
  const [site, setSite] = useState("");
  const [syncStartDate, setSyncStartDate] = useState(null);
  const [name, setName] = useState("");
  const [woocommerceErrors, setWoocommerceErrors] = useState({});
  const [accountsConfigured, setAccountsConfigured] = useState(false);
  const [saveShopLoading, setSaveShopLoading] = useState(false);
  const [saveConfigLoading, setSaveConfigLoading] = useState(false);
  const [saveSyncLoading, setSaveSyncLoading] = useState(false);
  const [deskeraProductUom, setDeskeraProductUom] = useState([]);
  const [saveTransactionSyncLoading, setSaveTransactionSyncLoading] = useState(false);
  const [syncInOutTransactions, setSyncInOutTransactions] = useState(
    config.data.syncInOutTransactions
  );
  const [autoSyncInOutTransactions, setAutoSyncInOutTransactions] = useState(
    config.data.autoSyncInOutTransactions
  );
  const [syncFromAllWarehouses, setSyncFromAllWarehouses] = useState(
    config.data.syncFromAllWarehouses
  );
  const useDocumentCodeFromWoocommerceForOrders = [
    { value: true, label: "YES" },
    { value: false, label: "NO" }
  ];
  const useDocumentCodeFromWoocommerceForProducts = [
    { value: "PRODUCT_ID", label: "Use Product ID from Woocommerce" },
    { value: "SKU", label: "Use SKU from Woocommerce" },
    { value: "FALSE", label: "Use Default Deskera ID" },
  ];
  const productType = [
    { value: "TRACKED", label: "Tracked (Goods)" },
    { value: "NON_TRACKED", label: "Non-Tracked (Services)" },
    { value: "WOOCOMMERCE_TYPE", label: "Woocommerce Type" },
  ];
  const [deskeraTaxes, setDeskeraTaxes] = useState([]);
  const [wConnectLoading, setWConnectLoading] = useState(false);
  const [configPaymentAccountMappings, setConfigPaymentAccountMappings] =
  useState([]);
  const [configTaxMappings, setConfigTaxMappings] =
  useState([]);
  const paymentConfig = useSelector((state) => state.woocommerce.paymentConfig);
  const taxMapping = useSelector((state) => state.woocommerce.taxMapping);
  const productAttributes = useSelector((state) => state.woocommerce.productAttributes);
  const [configProductAttributeMappings, setConfigProductAttributeMappings] =
  useState([]);
  const [saveProductAttributeConfigLoading, setsaveProductAttributeConfigLoading] =
  useState(false);
  const [paymentAccountMappingConfigured, setpaymentAccountMappingConfigured] =
  useState(false);
  const [taxMappingConfigured, setTaxMappingConfigured] =
  useState(false);
  const [savePaymentAccountConfigLoading, setSavePaymentAccountConfigLoading] =
  useState(false);
  const [saveTaxMappingConfigLoading, setSaveTaxMappingConfigLoading] =
  useState(false);
  const userInfo = useSelector((state) => state.auth.user);

  const useBooksTaxRatesOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];


  const configAccounts = [
    {
      name: "Bank Account",
      accessor: "bankAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Purchase Account",
      accessor: "productPurchaseAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Account",
      accessor: "productSalesAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Payable Account",
      accessor: "contactPayableAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Receivable Account",
      accessor: "contactReceivableAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Return Account",
      accessor: "productSalesReturnAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Purchase Return Account",
      accessor: "productPurchaseReturnAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Cost of Goods Sold Account",
      accessor: "productCostOfGoodsSoldAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Default Purchase Tax",
      accessor: "productPurchaseTaxCode",
      options: deskeraTaxes,
    },
    {
      name: "Default Sales Tax",
      accessor: "productSalesTaxCode",
      options: deskeraTaxes,
    },
    {
      name: "Inventory Account",
      accessor: "productInventoryAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Stock Adjustment Account",
      accessor: "productStockAdjustmentAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Product Warehouse",
      accessor: "productWarehouseCode",
      options: deskeraWarehouses,
    },
    ,
    {
      name: "Product Unit of Measure",
      accessor: "productUom",
      options: deskeraProductUom,
    },
    {
      name: "Use Document Code from Woocommerce for Orders",
      accessor: "useDocumentCodeFromWoocommerceForOrders",
      options: useDocumentCodeFromWoocommerceForOrders,
    },
    { 
      name: "Use Document Code from Woocommerce for Products",
      accessor: "useDocumentCodeFromWoocommerceForProducts",
      options: useDocumentCodeFromWoocommerceForProducts,
    },
    { 
      name: "Product Type",
      accessor: "productType",
      options: productType,
    },
    {
      name: "Ignore Woocommerce taxes and use Tax Rates from Books",
      accessor: "useBooksTaxRates",
      options: useBooksTaxRatesOptions
    },
    {
      name: "Hide Sync Products",
      accessor: "hideProducts",
      options: useDocumentCodeFromWoocommerceForOrders
    }
  ];

  async function getBooksTaxes() {
    try {
      const taxes = await getDeskeraBooksTaxes();
      const taxesOptions = [];
      taxes.forEach((tax) => {
        taxesOptions.push({ value: tax.code, label: tax.name });
      });
      setDeskeraTaxes(taxesOptions);
    } catch {
      setError("Failed to load Deskera Books Taxes");
    }
  }

  async function getBooksAccounts() {
    try {
      const accounts = await getDeskeraBooksAccounts();
      const accountsOptions = [];
      accounts.forEach((account) => {
        accountsOptions.push({ value: account.code, label: account.name });
      });
      setDeskeraAccounts(accountsOptions);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }
  
  async function getBooksDimensions() {
    try {
      const accounts = await getDeskeraDimensions();
      const dimensionOptions = [];
      accounts.forEach((account) => {
        dimensionOptions.push({ value: account.code, label: account.label });
      });
      setDeskeraDimensions(dimensionOptions);
    } catch {
      setError("Failed to load Deskera Dimensions");
    }
  }

  async function getBooksWarehouses() {
    try {
      const warehouses = await getDeskeraBooksWarehouses();
      const warehouseOptions = [];
      warehouses.forEach((warehouse) => {
        warehouseOptions.push({ value: warehouse.code, label: warehouse.name });
      });
      setDeskeraWarehouses(warehouseOptions);
    } catch {
      setError("Failed to load Deskera Books warehouses");
    }
  }

  async function getBooksProductUom() {
    try {
      const productUom = await getDeskeraProductUom();
      const productUomOptions = [];
      productUom.forEach((uom) => {
        productUomOptions.push({ value: uom.id, label: uom.name });
      });
      setDeskeraProductUom(productUomOptions);
    } catch {
      setError("Failed to load Deskera Books products uom");
    }
  }

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadConfig());
    getBooksAccounts();
    getBooksDimensions();
    getBooksWarehouses();
    getBooksProductUom();
    getBooksTaxes();
    dispatch(loadPaymentAccountConfig());
    dispatch(loadTaxMapping());
    dispatch(loadProuductAttributes())
  }, []);

  useEffect(() => {
    handleConfigLoad();
  }, [config]);

  useEffect(() => setSyncStartDate(userInfo?.bookBeginningStartDate), [userInfo]);

  function handleConfigLoad() {
    setAccountsConfigured(true);
    configAccounts.forEach((configAccount) => {
      if (!config.data[configAccount.accessor]) {
        setAccountsConfigured(false);
        return;
      }
    });
  }

  useEffect(() => { initialiseConfigPaymentAccountMappings() }, [paymentConfig, deskeraAccounts]);
  function initialiseConfigPaymentAccountMappings() {
    const configPaymentAccountMapping = [];

    for (let i = 0; i < paymentConfig.data.length; i++) {
      let payment = paymentConfig.data[i];
      let Name = payment.gateway_name;
      let options = deskeraAccounts;
      let configPayment = {
        gateway_name: Name,
        account_code: payment.account_code,
        options: options,
      };
      configPaymentAccountMapping.push(configPayment);
    }
    setConfigPaymentAccountMappings(configPaymentAccountMapping);
  }
  useEffect(() => { initialiseConfigTaxMappings() }, [taxMapping, deskeraTaxes]);
  function initialiseConfigTaxMappings() {
    const configTaxMapping = [];

    for (let i = 0; i < taxMapping.data.length; i++) {
      let taxMap = taxMapping.data[i];
      let options = deskeraTaxes;
      let configTax = {
        class: taxMap.class,
        display: taxMap.state +"-" +taxMap.name+"(Rate:"+taxMap.rate+")(Class:" +taxMap.class+")",
        country: taxMap.country,
        id: taxMap.id,
        name: taxMap.name,
        rate: taxMap.rate,
        state: taxMap.state,
        options: options,
        deskeraTaxCode: taxMap.deskeraTaxCode,
        deskeraTaxName: taxMap.deskeraTaxName
      };
      configTaxMapping.push(configTax);
    }
    setConfigTaxMappings(configTaxMapping);
  }


  function setPaymentAccount(e, i) {
    const payments = paymentConfig.data;
    const conf = payments.splice(i, 1)[0];

    conf.account_code = e.value;
    conf.account_name = e.label;
    payments.splice(i, 0, conf);

    dispatch(setPaymentMethodConfig(payments));
  }

  function setTaxMappingOnSelect(e, i) {
    const mapping = taxMapping.data;
    const conf = mapping.splice(i, 1)[0];

    conf.deskeraTaxCode = e.value;
    conf.deskeraTaxName = e.label;
    mapping.splice(i, 0, conf);

    dispatch(setTaxMapping(mapping));
  }
  async function savePaymentAccountConfig(e) {
    e.preventDefault();
    setSavePaymentAccountConfigLoading(true);
    try {
      const configResp = await saveWoocomPaymentMethodNewConfig(
        paymentConfig.data
      );
      dispatch(setPaymentMethodConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Payment Account updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSavePaymentAccountConfigLoading(false);
    dispatch(loadPaymentAccountConfig());
  }
  async function saveTaxMappingConfig(e) {
    e.preventDefault();
    setSaveTaxMappingConfigLoading(true);
    try {
      const configResp = await saveWoocomTaxMappings(
        taxMapping.data
      );
      dispatch(setTaxMapping(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Tax mappings updated successfully.",
        })
      );
    } catch {
      setError("Failed to save tax mappings");
    }
    setSaveTaxMappingConfigLoading(false);
    dispatch(loadTaxMapping());
  }
  
  useEffect(() => { initialiseConfigProductAttributeMappings() }, [productAttributes, deskeraDimensions]);
  function initialiseConfigProductAttributeMappings() {
    const configProductAttributeMapping = [];

    for (let i = 0; i < productAttributes.data.length; i++) {
      let attribute = productAttributes.data[i];
      let options = deskeraDimensions;
      let configAttribute = {
        id: attribute.id,
        name: attribute.name,
        deskeraCode: attribute.deskeraCode,
        deskeraLabel: attribute.deskeraLabel,
        options: options,
      };
      configProductAttributeMapping.push(configAttribute);
    }
    setConfigProductAttributeMappings(configProductAttributeMapping);
  }


  function setProductAttribute(e, i) {
    const attributes = productAttributes.data;
    const conf = attributes.splice(i, 1)[0];

    conf.deskeraCode = e.value;
    conf.deskeraLabel = e.label;
    attributes.splice(i, 0, conf);

    dispatch(setProductAttributes(attributes));
  }
  async function saveProductAttribute(e) {
    e.preventDefault();
    setsaveProductAttributeConfigLoading(true);
    try {
      const configResp = await saveWoocomProductAttributes(
        productAttributes.data
      );
      dispatch(setProductAttributes(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Product attribute mapping updated successfully.",
        })
      );
    } catch {
      setError("Failed to set product attributes");
    }
    setsaveProductAttributeConfigLoading(false);
    dispatch(loadProuductAttributes());
  }

  async function handleAddWoocommerceAccount(e) {
    e.preventDefault();

    if (!name || name === "") {
      setWoocommerceErrors({ name: "Enter a Woocommerce Store name" });
      return;
    }
    if (!site || site === "") {
      setWoocommerceErrors({ site: "Enter a Woocommerce Store URL" });
      return;
    }
    if (!syncStartDate) {
      setWoocommerceErrors({ syncStartDate: "Enter a Sync Start Date" });
      return;
    }
    setWoocommerceErrors({});

    setWConnectLoading(true);
    try {
      const woocommerceConnectUrl = await getWoocommerceRedirectUrl(site, name, syncStartDate);
      window.open(woocommerceConnectUrl.url, "_self");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setWoocommerceErrors({ site: err.response.data.message });
        setWConnectLoading(false);
      } else {
        setWoocommerceErrors({ site: "An error occured. Please try again." });
        setWConnectLoading(false);
      }
    }
  }

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch deskera url");
    }
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleDisconnectWoocommerceAccount(e, accountId) {
    e.preventDefault();
    try {
      await setDisconnectedWoocommerceAccount(accountId);
      dispatch(loadAccounts());
    } catch {
      setError("Failed to disconnect Woocommerce account");
    }
  }

  async function handleUpdateSync(e, syncStatus) {
    e.preventDefault();
    const newConfig = { ...config.data, syncEnabled: syncStatus };
    setSaveSyncLoading(true);
    try {
      const configResp = await setWoocommerceConfig(newConfig);
      dispatch(setConfig(configResp));
      setSyncButton(true);
      setSyncWarning(false);
    } catch {
      setError("Failed to set config");
    }
    setSaveSyncLoading(false);
  }

  async function saveAccountConfigs(e) {
    e.preventDefault();
    setSaveConfigLoading(true);
    try {
      const configResp = await setWoocommerceConfig(config.data);
      dispatch(setConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Accounts config updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveConfigLoading(false);
  }


  async function saveTransactionSync(e) {
    e.preventDefault();
    setSaveTransactionSyncLoading(true);
    const body = {
      twoWaySync: config.data.productTwoWaySyncEnabled ? config.data.productTwoWaySyncEnabled : false,
      syncInOutTransactions: config.data.syncInOutTransactions
      ? config.data.syncInOutTransactions
      : false,
      autoSyncInOutTransactions: config.data.autoSyncInOutTransactions
      ? config.data.autoSyncInOutTransactions
      : false,
      syncFromAllWarehouses: config.data.syncFromAllWarehouses
      ? config.data.syncFromAllWarehouses
      : false
    };
    try {
      const configResp = await saveWoocommerceTwoWaySync(body);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync setting updated successfully.",
        })
      );
    } catch {
      setError("Failed to save Transaction sync setting.");
    }

    const newConfig = {...config.data};

    try {
      const resp = await setWoocommerceConfig(newConfig);
      const configRes = resp.config
      configRes.syncEnabled = resp.syncEnabled
      configRes.productTwoWaySyncEnabled = resp.productTwoWaySyncEnabled
      configRes.syncInOutTransactions = resp.syncInOutTransactions
      configRes.autoSyncInOutTransactions = resp.autoSyncInOutTransactions
      configRes.syncFromAllWarehouses = resp.syncFromAllWarehouses
      dispatch(setConfig(configRes));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync config updated successfully.",
        })
      );
      setFormErrors([]);
    } catch {
      setError("Failed to set config");
    }
    setSaveTransactionSyncLoading(false);
  }

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={handleAddDeskeraAccount}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera Account is Connected</p>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            // <DKButton
            //   className="bg-danger px-3 text-white"
            //   onClick={(e) => setDisconnectWarning(true)}
            //   title={"Disconnect"}
            //   style={{ marginRight: "15px" }}
            // ></DKButton>
            <button
              // style={{ marginRight: "20px" }}
              onClick={(e) => setDisconnectWarning(true)}
              className="btn border-radius-m p-v-s text-white bg-danger"
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className="m-0 flex-fill" type="warning">
              <p className="m-0">Disconnecting will delete data. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is enabled for your account. Orders, Products and Customers from your Woocommerce
          store will be synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {syncButton && (
            <button
              className="btn border-radius-m p-v-s text-white bg-danger"
              onClick={(e) => setSyncWarning(true)}
              // style={{ marginRight: "20px" }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <SyncDisableIcon />
              </span>
              <span>Disable Sync</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Data will no longer be synced. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button className="btn dk-btn mr-2 px-3" onClick={(e) => setSyncWarning(false)}>
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncDisabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is disabled for your account. Orders, Products and Customers from your Woocommerce
          store are not being synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
            // style={{ marginRight: "20px" }}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <SyncIcon />
            </span>
            <span>Enable Sync</span>
          </button>
        </div>
      </div>
    );
  }

  function woocommerceConnect() {
    return (
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="card w-600 dk-card dk-card-shadow mb-4">
          <div className="card-body">
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Store Name</b>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Ex. Nunes Greens"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {woocommerceErrors.name && (
                <span className="text-danger">{woocommerceErrors.name}</span>
              )}
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Store URL</b>
              </div>
              <input
                id="site"
                type="text"
                className="form-control"
                placeholder="Ex. https://nunes-greens.com"
                value={site}
                onChange={(e) => setSite(e.target.value)}
              />
              {woocommerceErrors.site && (
                <span className="text-danger">{woocommerceErrors.site}</span>
              )}
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Sync Start Date</b>
              </div>
              <input
                id="syncStartDate"
                type="date"
                className="form-control"
                value={syncStartDate}
                min={userInfo.bookBeginningStartDate}
                onChange={(e) => setSyncStartDate(e.target.value)}
              />
              {woocommerceErrors.syncStartDate && (
                <span className="text-danger">{woocommerceErrors.syncStartDate}</span>
              )}
            </div>
            <div className="d-flex flex-row-reverse">
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={handleAddWoocommerceAccount}
                disabled={wConnectLoading}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {wConnectLoading ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <PlusLogo />
                  )}
                </span>
                <span>Connect</span>
              </button>
              <button
                className="btn border-radius-m p-v-s mr-2 text-white bg-danger"
                onClick={(e) => setConfigOpen(false)}
                // style={{marginRight: "10px"}}
              >
                <span className="svg-icon svg-baseline mr-2 svg-disabled svg-white">
                  <CloseIcon />
                </span>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function woocommerceConnectModal() {
    return (
      <div className="card w-600 mb-4 dk-card dk-card-shadow">
        <div className="card-body">
          <p className="text-muted">Link your Woocommerce accounts.</p>
          <div className="d-flex flex-row-reverse mb-2">
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setConfigOpen(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <PlusLogo />
              </span>
              <span>
                {accounts.data.length ? "Add Another Store" : "Connect Woocommerce Store"}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  async function disconnectWoocommerceStore(e, accountId) {
    await handleDisconnectWoocommerceAccount(e, accountId);
    setDisconnectWarningWoocommerce(false);
  }

  function woocommerceConnectedModal() {
    return (
      <>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            <p className="text-muted">Your Woocommerce account is linked.</p>
            {accounts.data.length > 0 && (
              <table className="table m-0 table-no-padding">
                <thead>
                  <tr>
                    <th>Account</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.data.map((account, index) => (
                    <div>
                      {!disconnectWarningWoocommerce && (
                        <tr style={{ width: "560px" }}>
                          <td className="align-middle" style={{ width: "10%" }}>
                            <p className="m-0">
                              <b>{account.name}</b>
                            </p>
                            <span class="d-inline-block text-truncate" style={{ width: "auto" }}>
                              <small>{account.id}</small>
                            </span>
                          </td>
                          <td className="align-middle text-right">
                            <button
                              // style={{ marginLeft: "10px" }}
                              onClick={(e) => setDisconnectWarningWoocommerce(true)}
                              className="btn border-radius-m p-v-s text-white bg-danger"
                            >
                              <span className="svg-icon svg-baseline mr-2 svg-white">
                                <DeleteLogo />
                              </span>
                              <span>Disconnect</span>
                            </button>
                          </td>
                        </tr>
                      )}

                      <CSSTransition
                        in={disconnectWarningWoocommerce}
                        timeout={100}
                        classNames="scale-opacity"
                        unmountOnExit
                        onEnter={() => setDisconnectButtonWoocommerce(false)}
                        onExited={() => setDisconnectButtonWoocommerce(true)}
                      >
                        <Alert className="m-0 flex-fill" type="warning">
                          <p className="m-0">Disconnect Woocommerce Store?</p>
                          <div className="d-flex flex-row-reverse">
                            <button
                              className="btn dk-btn font-weight-bold text-muted px-3"
                              onClick={(e) => {
                                disconnectWoocommerceStore(e, account.id);
                              }}
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CheckIcon />
                              </span>
                            </button>
                            <button
                              className="btn dk-btn mr-2 px-3"
                              onClick={(e) => setDisconnectWarningWoocommerce(false)}
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CloseIcon />
                              </span>
                            </button>
                          </div>
                        </Alert>
                      </CSSTransition>
                    </div>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="p-4 w-auto">
      {configOpen && woocommerceConnect()}
      <h3 className="mb-4">Account Setup</h3>

      {config.data?.disableSyncReason && (
        <div className="mb-4">
          <Alert type="warning">{config.data?.disableSyncReason}</Alert>
        </div>
      )}

      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>
      {deskeraInfo.accountConnected && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Woocommerce Stores</h5>
          </div>
          {accounts.data.length ? woocommerceConnectedModal() : woocommerceConnectModal()}
        </>
      )}
      {/* <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              <p className="text-muted">Link your Woocommerce accounts.</p>
              <div className="d-flex flex-row-reverse mb-2">
                <button
                  className="btn dk-btn font-weight-bold text-success px-3 border border-success"
                  onClick={(e) => setConfigOpen(true)}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    <PlusLogo />
                  </span>
                  <span>Add Store</span>
                </button>
              </div>
              {accounts.data.length > 0 && (
                <table className="table m-0 table-no-padding">
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accounts.data.map((account, index) => (
                      <tr>
                        <td className="align-middle">
                          <p className="m-0">
                            <b>{account.name}</b>
                          </p>
                          <span>
                            <small>{account.id}</small>
                          </span>
                        </td>
                        <td className="align-middle text-right" style={{ width: 180 }}>
                          <button
                            className="btn text-danger px-4 dk-btn border border-danger"
                            onClick={(e) => handleDisconnectWoocommerceAccount(e, account.id)}
                          >
                            <span className="svg-icon svg-baseline mr-2 svg-danger">
                              <DeleteLogo />
                            </span>
                            <span>Disconnect</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div> */}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Books Accounts</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!accountsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before Woocommerce products can be synced with Deskera
                  Books.
                </Alert>
              )}
              {configAccounts.map((configAccount) => (
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>{configAccount.name}</b>
                  </div>
                  <Select
                    placeholder="Select an Account"
                    theme={customSelectTheme}
                    options={configAccount.options}
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch(setConfig({ ...config.data, [configAccount.accessor]: e.value }))
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={configAccount.options.filter(
                      (obj) => obj.value === config.data[configAccount.accessor]
                    )}
                  />
                  {formErrors[configAccount.accessor] && (
                    <div>{formErrors[configAccount.accessor]}</div>
                  )}
                </div>
              ))}
              <div className="d-flex flex-row-reverse">
                <button
                  onClick={saveAccountConfigs}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  disabled={saveConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {saveConfigLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
            {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Payment Gateway Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!paymentAccountMappingConfigured && !paymentConfig && (
                <Alert type="primary">
                  Accounts must be configured before Payment Accounts can be
                  synced with Deskera Books.
                </Alert>
              )}
              {configPaymentAccountMappings.length === 0 && (
                <span className="mt-2 text-muted">
                  No payment method accounts found.
                </span>
              )}
              {configPaymentAccountMappings.length > 0 &&
                configPaymentAccountMappings.map(
                  (configPaymentAccountMapping, idx) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{configPaymentAccountMapping.gateway_name}</b>
                      </div>
                      <Select
                        placeholder="Select an Account"
                        theme={customSelectTheme}
                        options={configPaymentAccountMapping.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setPaymentAccount(e, idx);
                        }}
                        value={configPaymentAccountMapping.options.filter(
                          (obj) =>
                            obj.value ===
                            configPaymentAccountMapping.account_code
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {formErrors[
                        configPaymentAccountMapping.account_code
                      ] && (
                        <div>
                          {
                            formErrors[
                              configPaymentAccountMapping.account_code
                            ]
                          }
                        </div>
                      )}
                    </div>
                  )
                )}
              {configPaymentAccountMappings.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={savePaymentAccountConfig}
                    disabled={savePaymentAccountConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {savePaymentAccountConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Product Attribute Configuration</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!productAttributes && (
                <Alert type="primary">
                  Product attributes should be configured before Products can be
                  synced with Deskera Books.
                </Alert>
              )}
              {configProductAttributeMappings.length === 0 && (
                <span className="mt-2 text-muted">
                  No product attributes found.
                </span>
              )}
              {configProductAttributeMappings.length > 0 &&
                configProductAttributeMappings.map(
                  (configProductAttributeMapping, idx) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{configProductAttributeMapping.name}</b>
                      </div>
                      <Select
                        placeholder="Select a Dimension"
                        theme={customSelectTheme}
                        options={configProductAttributeMapping.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setProductAttribute(e, idx);
                        }}
                        value={configProductAttributeMapping.options.filter(
                          (obj) =>
                            obj.value ===
                            configProductAttributeMapping.deskeraCode
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {formErrors[
                        configProductAttributeMapping.deskeraCode
                      ] && (
                        <div>
                          {
                            formErrors[
                              configProductAttributeMapping.deskeraCode
                            ]
                          }
                        </div>
                      )}
                    </div>
                  )
                )}
              {configProductAttributeMappings.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={saveProductAttribute}
                    disabled={saveProductAttributeConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {saveProductAttributeConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      

      {deskeraInfo.accountConnected && accounts.data.length>0 && deskeraInfo.taxResidency === "IN" && deskeraTaxes.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Tax Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!taxMappingConfigured && !taxMapping && (
                <Alert type="primary">
                  Tax must be configured before Invoices can be
                  synced with Deskera Books.
                </Alert>
              )}
              {configTaxMappings.length === 0 && (
                <span className="mt-2 text-muted">
                  No Taxes found at Woocommerce.
                </span>
              )}
              {configTaxMappings.length > 0 &&
                configTaxMappings.map(
                  (configTaxMapping, idx) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{configTaxMapping.display}</b>
                      </div>
                      <Select
                        placeholder="Select a Tax"
                        theme={customSelectTheme}
                        options={configTaxMapping.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setTaxMappingOnSelect(e, idx);
                        }}
                        value={configTaxMapping.options.filter(
                          (obj) =>
                            obj.value ===
                            configTaxMapping.deskeraTaxCode
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {formErrors[
                        configTaxMapping.deskeraTaxCode
                      ] && (
                        <div>
                          {
                            formErrors[
                              configTaxMapping.deskeraTaxCode
                            ]
                          }
                        </div>
                      )}
                    </div>
                  )
                )}
              {configTaxMappings.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={saveTaxMappingConfig}
                    disabled={saveTaxMappingConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {saveTaxMappingConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {config.data.syncEnabled ? syncEnabledModal() : syncDisabledModal()}
          </div>
        </>
      )}
      {config.data.syncEnabled && deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Two Way Sync Settings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!accountsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before enabling two way sync </Alert>
              )}
              {
                <>
                  <div className="form-group">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox2"
                        defaultChecked={config.data.productTwoWaySyncEnabled}
                        onChange={() => {
                          if (config.data.productTwoWaySyncEnabled) {
                            dispatch(
                              setConfig({
                                ...config.data,
                                productTwoWaySyncEnabled: !config.data.productTwoWaySyncEnabled,
                              })
                            );
                          } else {
                            dispatch(
                              setConfig({
                                ...config.data,
                                productTwoWaySyncEnabled: !config.data.productTwoWaySyncEnabled
                              })
                            );
                          }
                        }}
                      />
                      <label class="form-check-label text-muted" for="inlineCheckbox2">
                        Enable Products two-way sync
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox4"
                        defaultChecked={syncInOutTransactions}
                        onChange={() => {
                          dispatch(
                            setConfig({
                              ...config.data,
                              syncInOutTransactions: !syncInOutTransactions,
                            })
                          );
                          setSyncInOutTransactions(!syncInOutTransactions);
                        }}
                      />
                      <label class="form-check-label text-muted" for="inlineCheckbox4">
                        Enable Stock IN/OUT Sync from Deskera to Woo-commerce
                      </label>
                    </div>
                    {syncInOutTransactions && (
                      <div class="form-check form-check-inline ml-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox5"
                          defaultChecked={autoSyncInOutTransactions}
                          onChange={() => {
                            dispatch(
                              setConfig({
                                ...config.data,
                                autoSyncInOutTransactions: !autoSyncInOutTransactions,
                              })
                            );
                            setAutoSyncInOutTransactions(!autoSyncInOutTransactions);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox5">
                          Auto Sync Stock IN/OUT after each 30 minutes
                        </label>
                      </div>
                      
                    )}
                    {syncInOutTransactions && (
                      <div class="form-check form-check-inline ml-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox6"
                          defaultChecked={syncFromAllWarehouses}
                          onChange={() => {
                            dispatch(
                              setConfig({
                                ...config.data,
                                syncFromAllWarehouses: !syncFromAllWarehouses,
                              })
                            );
                            setSyncFromAllWarehouses(!syncFromAllWarehouses);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox6">
                          Sync books inventory transactions from all warehouses to Woocommerce
                        </label>
                      </div>
                      
                    )}
                  </div>
                </>
              }
              {
                <div className="d-flex flex-row-reverse">
                  <button
                    onClick={(e) => saveTransactionSync(e)}
                    disabled={saveSyncLoading}
                    className="btn border-radius-m p-v-s text-white bg-success"
                  // style={{ marginRight: "20px" }}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-white">
                      {saveSyncLoading ? (
                        <div
                          class="spinner-border text-white spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Config;

