export const CREATE_TOAST = "CREATE_TOAST";
export const DELETE_TOAST = "DELETE_TOAST";
export const MARK_TOAST_DELETED = "MARK_TOAST_DELETED";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_ORDERS = "SET_ORDERS";
export const FAILED_ACCOUNT_LOAD = "FAILED_ACCOUNT_LOAD";
export const FAILED_ORDERS_LOAD = "FAILED_ORDERS_LOAD";
export const SET_DESKERA_INFO = "SET_DESKERA_INFO";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const FAILED_CUSTOMERS_LOAD = "FAILED_CUSTOMERS_LOAD";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const FAILED_PRODUCTS_LOAD = "FAILED_PRODUCTS_LOAD";
export const SET_AMAZON_CONFIG = "SET_AMAZON_CONFIG";
export const FAILED_AMAZON_CONFIG_LOAD = "FAILED_AMAZON_CONFIG_LOAD";
export const SET_MATCHING_PRODUCTS = "SET_MATCHING_PRODUCTS";

/* shopify action types */
export const SHOPIFY_SET_ACCOUNTS = "SHOPIFY_SET_ACCOUNTS";
export const SHOPIFY_SET_ORDERS = "SHOPIFY_SET_ORDERS";
export const SHOPIFY_FAILED_ACCOUNT_LOAD = "SHOPIFY_FAILED_ACCOUNT_LOAD";
export const SHOPIFY_FAILED_ORDERS_LOAD = "SHOPIFY_FAILED_ORDERS_LOAD";
export const SHOPIFY_SET_REFUNDS = "SHOPIFY_SET_REFUNDS";
export const SHOPIFY_FAILED_REFUNDS_LOAD = "SHOPIFY_FAILED_REFUNDS_LOAD";
export const SHOPIFY_SET_CUSTOMERS = "SHOPIFY_SET_CUSTOMERS";
export const SHOPIFY_SET_INVENTORY_TRANSACTIONS = "SHOPIFY_SET_INVENTORY_TRANSACTIONS";
export const SHOPIFY_FAILED_CUSTOMERS_LOAD = "SHOPIFY_FAILED_CUSTOMERS_LOAD";
export const SHOPIFY_FAILED_INVENTORY_TRANSACTIONS_LOAD =
  "SHOPIFY_FAILED_INVENTORY_TRANSACTIONS_LOAD";
export const SHOPIFY_SET_PRODUCTS = "SHOPIFY_SET_PRODUCTS";
export const SHOPIFY_CONTACT_CUSTOMER_TYPE = "SHOPIFY_CONTACT_CUSTOMER_TYPE";
export const SHOPIFY_FAILED_CONTACT_CUSTOMER_LOAD = "SHOPIFY_FAILED_CONTACT_CUSTOMER_LOAD";
export const SHOPIFY_SET_DESKERA_PRODUCTS = "SHOPIFY_SET_DESKERA_PRODUCTS";
export const SHOPIFY_FAILED_PRODUCTS_LOAD = "SHOPIFY_FAILED_PRODUCTS_LOAD";
export const SHOPIFY_SET_CONFIG = "SHOPIFY_SET_CONFIG";
export const SHOPIFY_FAILED_CONFIG_LOAD = "SHOPIFY_FAILED_CONFIG_LOAD";
export const SHOPIFY_SET_JOB = "SHOPIFY_SET_JOB";
export const SHOPIFY_FAILED_JOB_LOAD = "SHOPIFY_FAILED_JOB_LOAD";
export const SHOPIFY_SET_LOCATIONS = "SHOPIFY_SET_LOCATIONS";
export const SHOPIFY_FAILED_LOCATIONS_LOAD = "SHOPIFY_FAILED_LOCATIONS_LOAD";
export const SHOPIFY_SET_PAYMENT_CONFIG = "SHOPIFY_SET_PAYMENT_CONFIG";
export const SHOPIFY_FAILED_PAYMENT_LOAD = "SHOPIFY_FAILED_PAYMENT_LOAD";
export const SHOPIFY_SET_DISCOUNT_CONFIG = "SHOPIFY_SET_DISCOUNT_CONFIG";
export const SHOPIFY_FAILED_DISCOUNT_LOAD = "SHOPIFY_FAILED_DISCOUNT_LOAD";
export const SQUARE_MATCHING_PRODUCT = "SQUARE_MATCHING_PRODUCT";
export const MAGENTO_MATCHING_PRODUCT = "MAGENTO_MATCHING_PRODUCT";
export const MAGENTO_CUSTOM_FIELD = "MAGENTO_CUSTOM_FIELD";
export const MAGENTO_CUSTOM_FIELD_MAPPING = "MAGENTO_CUSTOM_FIELD_MAPPING";
export const DESKERA_CUSTOM_FIELD = "DESKERA_CUSTOM_FIELD";
export const MAGENTO_DISCOUNT_CODE = "MAGENTO_DISCOUNT_CODE";
export const MAGENTO_CUSTOMER_FILTER = "MAGENTO_CUSTOMER_FILTER";

/* woocommerce action types */
export const WOOCOMMERCE_SET_ACCOUNTS = "WOOCOMMERCE_SET_ACCOUNTS";
export const WOOCOMMERCE_SET_ORDERS = "WOOCOMMERCE_SET_ORDERS";
export const WOOCOMMERCE_FAILED_ACCOUNT_LOAD = "WOOCOMMERCE_FAILED_ACCOUNT_LOAD";
export const WOOCOMMERCE_FAILED_ORDERS_LOAD = "WOOCOMMERCE_FAILED_ORDERS_LOAD";
export const WOOCOMMERCE_SET_CUSTOMERS = "WOOCOMMERCE_SET_CUSTOMERS";
export const WOOCOMMERCE_FAILED_CUSTOMERS_LOAD = "WOOCOMMERCE_FAILED_CUSTOMERS_LOAD";
export const WOOCOMMERCE_SET_PRODUCTS = "WOOCOMMERCE_SET_PRODUCTS";
export const WOOCOMMERCE_FAILED_PRODUCTS_LOAD = "WOOCOMMERCE_FAILED_PRODUCTS_LOAD";
export const WOOCOMMERCE_SET_CONFIG = "WOOCOMMERCE_SET_CONFIG";
export const WOOCOMMERCE_FAILED_CONFIG_LOAD = "WOOCOMMERCE_FAILED_CONFIG_LOAD";
export const WOOCOMMERCE_SET_JOB = "WOOCOMMERCE_SET_JOB";
export const WOOCOMMERCE_FAILED_JOB_LOAD = "WOOCOMMERCE_FAILED_JOB_LOAD";
export const WOOCOMMERCE_SET_PAYMENT_CONFIG = "WOOCOMMERCE_SET_PAYMENT_CONFIG";
export const WOOCOMMERCE_FAILED_PAYMENT_LOAD = "WOOCOMMERCE_FAILED_PAYMENT_LOAD";
export const WOOCOMMERCE_SET_PRODUCT_ATTRIBUTES = "WOOCOMMERCE_SET_PRODUCT_ATTRIBUTES";
export const WOOCOMMERCE_FAILED_PRODUCT_ATTRIBUTES_LOAD =
  "WOOCOMMERCE_FAILED_PRODUCT_ATTRIBUTES_LOAD";
export const WOOCOMMERCE_SET_DESKERA_PRODUCTS = "WOOCOMMERCE_SET_DESKERA_PRODUCTS";
export const WOOCOMMERCE_SET_DESKERA_INVENTORY_TRANSACTION =
  "WOOCOMMERCE_SET_INVENTORY_TRANSACTION";
export const WOOCOMMERCE_SET_TAX_MAPPING = "WOOCOMMERCE_SET_TAX_MAPPING";
export const WOOCOMMERCE_FAILED_TAX_MAPPING_LOAD = "WOOCOMMERCE_FAILED_TAX_MAPPING_LOAD";


/* brightpearl action types */
export const BRIGHTPEARL_SET_CONFIG = "BRIGHTPEARL_SET_CONFIG";
export const BRIGHTPEARL_FAILED_CONFIG_LOAD = "BRIGHTPEARL_FAILED_CONFIG_LOAD";
export const BRIGHTPEARL_SET_CUSTOMERS = "BRIGHTPEARL_SET_CUSTOMERS";
export const BRIGHTPEARL_SET_INVENTORY_TRANSACTIONS = "BRIGHTPEARL_SET_INVENTORY_TRANSACTIONS";
export const BRIGHTPEARL_FAILED_CUSTOMERS_LOAD = "BRIGHTPEARL_FAILED_CUSTOMERS_LOAD";
export const BRIGHTPEARL_FAILED_INVENTORY_TRANSACTIONS_LOAD =
  "BRIGHTPEARL_FAILED_INVENTORY_TRANSACTIONS_LOAD";
export const BRIGHTPEARL_SET_ORDERS = "BRIGHTPEARL_SET_ORDERS";
export const BRIGHTPEARL_FAILED_ORDERS_LOAD = "BRIGHTPEARL_FAILED_ORDERS_LOAD";
export const BRIGHTPEARL_SET_PRODUCTS = "BRIGHTPEARL_SET_PRODUCTS";
export const BRIGHTPEARL_FAILED_PRODUCTS_LOAD = "BRIGHTPEARL_FAILED_PRODUCTS_LOAD";
export const BRIGHTPEARL_SET_ACCOUNTS = "BRIGHTPEARL_SET_ACCOUNTS";
export const BRIGHTPEARL_FAILED_ACCOUNT_LOAD = "BRIGHTPEARL_FAILED_ACCOUNT_LOAD";
export const BRIGHTPEARL_SET_JOB = "BRIGHTPEARL_SET_JOB";
export const BRIGHTPEARL_FAILED_JOB_LOAD = "BRIGHTPEARL_FAILED_JOB_LOAD";
export const BRIGHTPEARL_SET_PAYMENT_CONFIG = "BRIGHTPEARL_SET_PAYMENT_CONFIG";
export const BRIGHTPEARL_SET_PRODUCT_ATTRIBUTES = "BRIGHTPEARL_SET_PRODUCT_ATTRIBUTES";
export const BRIGHTPEARL_SET_DESKERA_PRODUCTS = "BRIGHTPEARL_SET_DESKERA_PRODUCTS";
export const BRIGHTPEARL_SET_DESKERA_INVENTORY_TRANSACTION = "BRIGHTPEARL_SET_DESKERA_INVENTORY_TRANSACTION";
export const BRIGHTPEARL_SET_TAX_MAPPING = "BRIGHTPEARL_SET_TAX_MAPPING";
export const BRIGHTPEARL_FAILED_TAX_MAPPING_LOAD = "BRIGHTPEARL_FAILED_TAX_MAPPING_LOAD"
export const BRIGHTPEARL_SET_WAREHOUSE_MAPPING = "BRIGHTPEARL_SET_WAREHOUSE_MAPPING";
export const BRIGHTPEARL_SET_WAREHOUSE_ERRORS = "BRIGHTPEARL_SET_WAREHOUSE_ERRORS";
export const BRIGHTPEARL_SET_MAPPED_DESKERA_WAREHOUSE = "BRIGHTPEARL_SET_MAPPED_DESKERA_WAREHOUSE";
export const BRIGHTEPARL_SET_DESKERA_WAREHOUSE = "BRIGHTEPARL_SET_DESKERA_WAREHOUSE";
export const BRIGHTPEARL_SET_MAPPED_DESKERA_WAREHOUSE_ERROR = "BRIGHTPEARL_SET_MAPPED_DESKERA_WAREHOUSE_ERROR";
export const BRIGHTPEARL_FAILED_WAREHOUSE_MAPPING_LOAD = "BRIGHTPEARL_FAILED_WAREHOUSE_MAPPING_LOAD";

/* square action types */
export const SQUARE_SET_ACCOUNTS = "SQUARE_SET_ACCOUNTS";
export const SQUARE_SET_ORDERS = "SQUARE_SET_ORDERS";
export const SQUARE_FAILED_ACCOUNT_LOAD = "SQUARE_FAILED_ACCOUNT_LOAD";
export const SQUARE_FAILED_ORDERS_LOAD = "SQUARE_FAILED_ORDERS_LOAD";
export const SQUARE_SET_CUSTOMERS = "SQUARE_SET_CUSTOMERS";
export const SQUARE_FAILED_CUSTOMERS_LOAD = "SQUARE_FAILED_CUSTOMERS_LOAD";
export const SQUARE_SET_PRODUCTS = "SQUARE_SET_PRODUCTS";
export const SQUARE_FAILED_PRODUCTS_LOAD = "SQUARE_FAILED_PRODUCTS_LOAD";
export const SQUARE_SET_CONFIG = "SQUARE_SET_CONFIG";
export const SQUARE_FAILED_CONFIG_LOAD = "SQUARE_FAILED_CONFIG_LOAD";
export const SQUARE_SET_TWOWAYCONFIG = "SQUARE_SET_TWOWAYCONFIG";
export const SQUARE_SET_JOB = "SQUARE_SET_JOB";
export const SQUARE_FAILED_JOB_LOAD = "SQUARE_FAILED_JOB_LOAD";
export const SQUARE_SET_PAYMENT_CONFIG = "SQUARE_SET_PAYMENT_CONFIG";
export const SQUARE_FAILED_PAYMENT_LOAD = "SQUARE_FAILED_PAYMENT_LOAD";
export const SQUARE_SET_LOCATION_CONFIG = "SQUARE_SET_LOCATION_CONFIG";
export const SQUARE_FAILED_LOCATION_LOAD = "SQUARE_FAILED_LOCATION_LOAD";
export const SQUARE_SET_MEASURE_CONFIG = "SQUARE_SET_MEASURE_CONFIG";
export const SQUARE_FAILED_MEASURE_LOAD = "SQUARE_FAILED_MEASURE_LOAD";
export const SQUARE_SET_DESKERA_PRODUCTS = "SQUARE_SET_DESKERA_PRODUCTS";
export const SQUARE_SET_CUSTOM_FIELD_CONFIG = "SQUARE_SET_CUSTOM_FIELD_CONFIG";
export const SQUARE_FAILED_CUSTOM_LOAD = "SQUARE_FAILED_CUSTOM_LOAD";
export const SQUARE_SET_INVENTORY_TRANSACTIONS = "SQUARE_SET_INVENTORY_TRANSACTIONS";
export const SQUARE_FAILED_INVENTORY_TRANSACTIONS_LOAD =
  "SQUARE_FAILED_INVENTORY_TRANSACTIONS_LOAD";

/* foodics action types */
export const FOODICS_SET_ACCOUNTS = "FOODICS_SET_ACCOUNTS";
export const FOODICS_SET_ORDERS = "FOODICS_SET_ORDERS";
export const FOODICS_FAILED_ACCOUNT_LOAD = "FOODICS_FAILED_ACCOUNT_LOAD";
export const FOODICS_FAILED_ORDERS_LOAD = "FOODICS_FAILED_ORDERS_LOAD";
export const FOODICS_SET_PURCHASES = "FOODICS_SET_PURCHASES";
export const FOODICS_FAILED_PURCHASES_LOAD = "FOODICS_FAILED_PURCHASES_LOAD";
export const FOODICS_SET_INVENTORY = "FOODICS_SET_INVENTORY";
export const FOODICS_FAILED_INVENTORY_LOAD = "FOODICS_FAILED_INVENTORY_LOAD";
export const FOODICS_SET_CONFIG = "FOODICS_SET_CONFIG";
export const FOODICS_FAILED_CONFIG_LOAD = "FOODICS_FAILED_CONFIG_LOAD";
export const FOODICS_SET_JOB = "FOODICS_SET_JOB";
export const FOODICS_FAILED_JOB_LOAD = "FOODICS_FAILED_JOB_LOAD";
export const FOODICS_SET_PRODUCT_CONFIG = "FOODICS_SET_PRODUCT_CONFIG";
export const FOODICS_FAILED_PRODUCT_CONFIG_LOAD = "FOODICS_FAILED_PRODUCT_CONFIG_LOAD";

/* shipStation action types */
export const SHIP_STATION_SET_ACCOUNTS = "SHIP_STATION_SET_ACCOUNTS";
export const SHIP_STATION_SET_ORDERS = "SHIP_STATION_SET_ORDERS";
export const SHIP_STATION_FAILED_ACCOUNT_LOAD = "SHIP_STATION_FAILED_ACCOUNT_LOAD";
export const SHIP_STATION_FAILED_ORDERS_LOAD = "SHIP_STATION_FAILED_ORDERS_LOAD";
export const SHIP_STATION_SET_CONFIG = "SHIP_STATION_SET_CONFIG";
export const SHIP_STATION_SET_STORES = "SHIP_STATION_SET_STORES";
export const SHIP_STATION_FAILED_CONFIG_LOAD = "SHIP_STATION_FAILED_CONFIG_LOAD";
export const SHIP_STATION_FAILED_STORE_LOAD = "SHIP_STATION_FAILED_STORE_LOAD";
export const SHIP_STATION_SET_JOB = "SHIP_STATION_SET_JOB";
export const SHIP_STATION_FAILED_JOB_LOAD = "SHIP_STATION_FAILED_JOB_LOAD";
export const SHIP_STATION_SET_INVOICES = "SHIP_STATION_SET_INVOICES";
export const SHIP_STATION_FAILED_INVOICES_LOAD = "SHIP_STATION_FAILED_INVOICES_LOAD";

// Magento action types
export const MAGENTO_SET_ACCOUNTS = "MAGENTO_SET_ACCOUNTS";
export const MAGENTO_SET_ORDERS = "MAGENTO_SET_ORDERS";
export const MAGENTO_FAILED_ACCOUNT_LOAD = "MAGENTO_FAILED_ACCOUNT_LOAD";
export const MAGENTO_FAILED_ORDERS_LOAD = "MAGENTO_FAILED_ORDERS_LOAD";
export const MAGENTO_SET_CUSTOMERS = "MAGENTO_SET_CUSTOMERS";
export const MAGENTO_SET_INVENTORY_TRANSACTIONS = "MAGENTO_SET_INVENTORY_TRANSACTIONS";
export const MAGENTO_FAILED_CUSTOMERS_LOAD = "MAGENTO_FAILED_CUSTOMERS_LOAD";
export const MAGENTO_FAILED_INVENTORY_TRANSACTIONS_LOAD =
  "MAGENTO_FAILED_INVENTORY_TRANSACTIONS_LOAD";
export const MAGENTO_SET_PRODUCTS = "MAGENTO_SET_PRODUCTS";
export const MAGENTO_SET_DESKERA_PRODUCTS = "MAGENTO_SET_DESKERA_PRODUCTS";
export const MAGENTO_FAILED_PRODUCTS_LOAD = "MAGENTO_FAILED_PRODUCTS_LOAD";
export const MAGENTO_SET_CONFIG = "MAGENTO_SET_CONFIG";
export const MAGENTO_FAILED_CONFIG_LOAD = "MAGENTO_FAILED_CONFIG_LOAD";
export const MAGENTO_SET_JOB = "MAGENTO_SET_JOB";
export const MAGENTO_FAILED_JOB_LOAD = "MAGENTO_FAILED_JOB_LOAD";
export const MAGENTO_SET_LOCATIONS = "MAGENTO_SET_LOCATIONS";
export const MAGENTO_FAILED_LOCATIONS_LOAD = "MAGENTO_FAILED_LOCATIONS_LOAD";
export const MAGENTO_SET_PAYMENT_CONFIG = "MAGENTO_SET_PAYMENT_CONFIG";
export const MAGENTO_FAILED_PAYMENT_LOAD = "MAGENTO_FAILED_PAYMENT_LOAD";

// Bigcommerce action types
export const BIGCOMMERCE_SET_ACCOUNTS = "BIGCOMMERCE_SET_ACCOUNTS";
export const BIGCOMMERCE_SET_ORDERS = "BIGCOMMERCE_SET_ORDERS";
export const BIGCOMMERCE_FAILED_ACCOUNT_LOAD = "BIGCOMMERCE_FAILED_ACCOUNT_LOAD";
export const BIGCOMMERCE_FAILED_ORDERS_LOAD = "BIGCOMMERCE_FAILED_ORDERS_LOAD";
export const BIGCOMMERCE_SET_CUSTOMERS = "BIGCOMMERCE_SET_CUSTOMERS";
export const BIGCOMMERCE_SET_INVENTORY_TRANSACTIONS = "BIGCOMMERCE_SET_INVENTORY_TRANSACTIONS";
export const BIGCOMMERCE_FAILED_CUSTOMERS_LOAD = "BIGCOMMERCE_FAILED_CUSTOMERS_LOAD";
export const BIGCOMMERCE_FAILED_INVENTORY_TRANSACTIONS_LOAD =
  "BIGCOMMERCE_FAILED_INVENTORY_TRANSACTIONS_LOAD";
export const BIGCOMMERCE_SET_PRODUCTS = "BIGCOMMERCE_SET_PRODUCTS";
export const BIGCOMMERCE_SET_DESKERA_PRODUCTS = "BIGCOMMERCE_SET_DESKERA_PRODUCTS";
export const BIGCOMMERCE_FAILED_PRODUCTS_LOAD = "BIGCOMMERCE_FAILED_PRODUCTS_LOAD";
export const BIGCOMMERCE_SET_CONFIG = "BIGCOMMERCE_SET_CONFIG";
export const BIGCOMMERCE_FAILED_CONFIG_LOAD = "BIGCOMMERCE_FAILED_CONFIG_LOAD";
export const BIGCOMMERCE_SET_JOB = "BIGCOMMERCE_SET_JOB";
export const BIGCOMMERCE_FAILED_JOB_LOAD = "BIGCOMMERCE_FAILED_JOB_LOAD";
export const BIGCOMMERCE_SET_LOCATIONS = "BIGCOMMERCE_SET_LOCATIONS";
export const BIGCOMMERCE_FAILED_LOCATIONS_LOAD = "BIGCOMMERCE_FAILED_LOCATIONS_LOAD";
export const BIGCOMMERCE_SET_PAYMENT_CONFIG = "BIGCOMMERCE_SET_PAYMENT_CONFIG";
export const BIGCOMMERCE_FAILED_PAYMENT_LOAD = "BIGCOMMERCE_FAILED_PAYMENT_LOAD";
export const BIGCOMMERCE_DISCOUNT_CODE = "BIGCOMMERCE_DISCOUNT_CODE";

/* Tally action types */
export const TALLY_SET_CONFIG = "TALLY_SET_CONFIG";
export const TALLY_FAILED_CONFIG_LOAD = "TALLY_FAILED_CONFIG_LOAD";
export const TALLY_SET_ACCOUNTS = "TALLY_SET_ACCOUNTS";
export const TALLY_FAILED_ACCOUNT_LOAD = "TALLY_FAILED_ACCOUNT_LOAD";
export const TALLY_SET_CREDIT_NOTES = "TALLY_SET_CREDIT_NOTES";
export const TALLY_FAILED_CREDIT_NOTES_LOAD = "TALLY_FAILED_CREDIT_NOTES_LOAD";
export const TALLY_SET_DEBIT_NOTES = "TALLY_SET_DEBIT_NOTES";
export const TALLY_FAILED_DEBIT_NOTES_LOAD = "TALLY_FAILED_DEBIT_NOTES_LOAD";
export const TALLY_SET_JOURNAL_VOUCHERS = "TALLY_SET_JOURNAL_VOUCHERS";
export const TALLY_FAILED_JOURNAL_VOUCHERS_LOAD = "TALLY_FAILED_JOURNAL_VOUCHERS_LOAD";
export const TALLY_SET_PRODUCTS = "TALLY_SET_PRODUCTS";
export const TALLY_FAILED_PRODUCTS_LOAD = "TALLY_FAILED_PRODUCTS_LOAD";
export const TALLY_SET_CUSTOMERS = "TALLY_SET_CUSTOMERS";
export const TALLY_FAILED_CUSTOMERS_LOAD = "TALLY_FAILED_CUSTOMERS_LOAD";
export const TALLY_SET_INVOICES = "TALLY_SET_INVOICES";
export const TALLY_FAILED_INVOICES_LOAD = "TALLY_FAILED_INVOICES_LOAD";
export const TALLY_SET_BILLS = "TALLY_SET_BILLS";
export const TALLY_FAILED_BILLS_LOAD = "TALLY_FAILED_BILLS_LOAD";
export const TALLY_SET_PAYMENTS = "TALLY_SET_PAYMENTS";
export const TALLY_FAILED_PAYMENTS_LOAD = "TALLY_FAILED_PAYMENTS_LOAD";
export const TALLY_SET_RECEIPTS = "TALLY_SET_RECEIPTS";
export const TALLY_FAILED_RECEIPTS_LOAD = "TALLY_FAILED_RECEIPTS_LOAD";
