import {
  disconnectBrightPearl,
  getBrightPearlConfig,
  getBrightPearlConnections,
  getBrightPearlCustomers,
  getBrightPearlInventoryTransactions,
  getBrightPearlOrders,
  getBrightPearlProducts,
  getBrightPearlWarehouseMappings,
  saveBrightPearlWarehouseMappings,
} from "src/api";
  
  import {
    BRIGHTPEARL_SET_CONFIG,
    BRIGHTPEARL_SET_ACCOUNTS,
    BRIGHTPEARL_FAILED_CONFIG_LOAD,
    BRIGHTPEARL_FAILED_ACCOUNT_LOAD,
    BRIGHTPEARL_SET_PRODUCTS,
    BRIGHTPEARL_FAILED_PRODUCTS_LOAD,
    BRIGHTPEARL_SET_CUSTOMERS,
    BRIGHTPEARL_FAILED_CUSTOMERS_LOAD,
    BRIGHTPEARL_SET_JOB,
    BRIGHTPEARL_FAILED_JOB_LOAD,
    BRIGHTPEARL_SET_WAREHOUSE_MAPPING,
    BRIGHTPEARL_FAILED_WAREHOUSE_MAPPING_LOAD,
    BRIGHTPEARL_SET_ORDERS,
    BRIGHTPEARL_FAILED_ORDERS_LOAD,
    BRIGHTPEARL_SET_INVENTORY_TRANSACTIONS,
    BRIGHTPEARL_FAILED_INVENTORY_TRANSACTIONS_LOAD,
    BRIGHTPEARL_SET_WAREHOUSE_ERRORS,
    BRIGHTPEARL_SET_MAPPED_DESKERA_WAREHOUSE,
    BRIGHTEPARL_SET_DESKERA_WAREHOUSE,
  } from "../types";
import { Utility } from "src/utils/Utility";
  
  
  export function loadConfig() {
    return async function (dispatch, getState) {
      try {
        const config = await getBrightPearlConfig();
        
        dispatch(setConfig(config));
      } catch (err) {
        dispatch(failedConfigLoad());
      }
    };
  }


  export function loadWarehouseMappingsWithNewWarehouseData() {
    return async function (dispatch, getState) {
      try {
        let warehouseErrors = [];
        const warehouseMappings = await getBrightPearlWarehouseMappings(true);
        const mappedDeskeraWarehouse = new Set();

        for (let accountCode in warehouseMappings) {
          const warehouses = warehouseMappings[accountCode];
          warehouses.forEach((warehouse) => {
            if (!Utility.isEmpty(warehouse?.error)) {
              warehouseErrors.push(warehouse.error	);
            }
          });
          mappedDeskeraWarehouse.add(warehouseMappings[accountCode].deskeraWarehouseCode)
        }
        dispatch(setWarehouseErrors(warehouseErrors));
        dispatch(setWarehouseMappings(warehouseMappings));
      } catch (err) {
        dispatch(failedWarehouseMappingsLoad());
      }
    };
  }
  

  export function setWarehouseErrors(warehouseErrors) {
    return {
      type: BRIGHTPEARL_SET_WAREHOUSE_ERRORS,
      warehouseErrors: warehouseErrors,
    };
  }

  export function setMappedDeskeraWarehousesSet(warehouses) {
    return {
      type: BRIGHTPEARL_SET_MAPPED_DESKERA_WAREHOUSE,
      payload: warehouses,
    };
  }

  export const processWarehouseData = (firstApiResponse, secondApiResponse) => (dispatch) => {
    const processedWarehouses = firstApiResponse.map((warehouse) => ({
      id: warehouse.id,
      name: warehouse.name,
      code: warehouse.code,
    }));

    const mappedWarehouses = new Set();

    Object.keys(secondApiResponse).forEach((accountCode) => {
      secondApiResponse[accountCode].forEach((warehouse) => {
        if (!Utility.isEmpty(warehouse.deskeraWarehouseCode)) {
          mappedWarehouses.add(warehouse.deskeraWarehouseCode);
        }
      });
    });
    const missingWarehouses = [];
    processedWarehouses.forEach((warehouse) => {
      if (!mappedWarehouses.has(warehouse.code)) {
        missingWarehouses.push(warehouse);
      }
    });

    const names = missingWarehouses.map((warehouse) => warehouse.name);

    const MSG = "%s Deskera warehouse(s) are not Mapped in Brightpearl";

    const array = [];

    if (names.length > 0) {
      array[0] = MSG.replace("%s", names.join(", "));
    }

    dispatch(setMappedDeskeraWarehousesSet(array));
  };


  export function loadWarehouseMappings() {
    return async function (dispatch, getState) {
      try {
        const warehouseMappings = await getBrightPearlWarehouseMappings(false);
        let warehouseErrors = [];
        for (let accountCode in warehouseMappings) {
          const warehouses = warehouseMappings[accountCode];
          warehouses.forEach((warehouse) => {
            if (!Utility.isEmpty(warehouse?.error)) {
              warehouseErrors.push(warehouse.error	);
            }
          });
        }
        dispatch(setWarehouseErrors(warehouseErrors));
        dispatch(setWarehouseMappings(warehouseMappings));
      } catch (err) {
        dispatch(failedWarehouseMappingsLoad());
      }
    };
  }
  

  export function failedWarehouseMappingsLoad() {
    return {
      type: BRIGHTPEARL_FAILED_WAREHOUSE_MAPPING_LOAD,
    };
  }
  

  export function setConfig(config) {
    
    return {
      type: BRIGHTPEARL_SET_CONFIG,
      config: config,
    };
  }
  
  export function failedConfigLoad() {
    return {
      type:BRIGHTPEARL_FAILED_CONFIG_LOAD,
    };
  }
  
  export function loadAccounts() {
    return async function (dispatch, getState) {
      try {
        const accounts = await getBrightPearlConnections();
        dispatch(setAccounts(accounts));
      } catch (err) {
        dispatch(failedAccountLoad());
      }
    };
  }

  // load products
  export function loadProducts(pageSize, pageIndex, status, sortBy, search) {
    return async function (dispatch, getState) {
      try {
        const products = await getBrightPearlProducts(pageSize, pageIndex, status, sortBy, search);
        dispatch(setProducts(products));
      } catch (err) {
        dispatch(failedProductLoad());
      }
    };
  }

  // load customers
  export function loadCustomers(pageSize, pageIndex, status, sortBy, search) {
    return async function (dispatch, getState) {
      try {
        const customers = await getBrightPearlCustomers(pageSize, pageIndex, status, sortBy, search);
        dispatch(setCustomers(customers));
      } catch (err) {
        dispatch(failedCustomerLoad());
      }
    };
  }

  export function loadInventoryTransactions(limit, page, status, sortBy, search, queryParams = "") {
    return async function (dispatch, getState) {
      try {
        const inventoryTransactions = await getBrightPearlInventoryTransactions(
          limit,
          page,
          sortBy,
          search,
          status,
          queryParams
        );
        
        dispatch(setInventoryTransactions(inventoryTransactions));
      } catch (err) {
        dispatch(failedInventoryTransactionsLoad());
      }
    };
  }

  export function setInventoryTransactions(inventoryTransactions) {
    return {
      type: BRIGHTPEARL_SET_INVENTORY_TRANSACTIONS,
      inventoryTransactions: inventoryTransactions,
    };
  }

  export function failedInventoryTransactionsLoad() {
    return {
      type: BRIGHTPEARL_FAILED_INVENTORY_TRANSACTIONS_LOAD,
    };
  }

  // load job
  // getBrightPearlJobStatus

export function loadJob() {
  return async function (dispatch) {
    try {
      // const job = await getShopifyJobStatus();
      const job = [];
      dispatch(setJob(job));
    } catch (err) {
      dispatch(failedJobLoad());
    }
  };
}

  export function disconnectAccount(accountId) {
    return async function (dispatch, getState) {
      try {
        await disconnectBrightPearl(accountId);
      } catch (err) {
        dispatch(failedAccountLoad());
      }finally {
        const accounts = await getBrightPearlConnections();
        dispatch(setAccounts(accounts));
      }
    };
  }
  
  export function setAccounts(accounts) {
    return {
      type: BRIGHTPEARL_SET_ACCOUNTS,
      accounts: accounts,
    };
  }
  
  export function failedAccountLoad() {
    return {
      type: BRIGHTPEARL_FAILED_ACCOUNT_LOAD,
    };
  }
  
  export function setProducts(products) {
    return {
      type: BRIGHTPEARL_SET_PRODUCTS,
      products: products,
    };
  }

  export function failedProductLoad() {
    return {
      type: BRIGHTPEARL_FAILED_PRODUCTS_LOAD,
    };
  }

  export function setCustomers(customers) {
    return {
      type: BRIGHTPEARL_SET_CUSTOMERS,
      customers: customers,
    };
  }

  export function failedCustomerLoad() {
    return {
      type: BRIGHTPEARL_FAILED_CUSTOMERS_LOAD,
    };
  }

  export function setJob(job) {
    return {
      type: BRIGHTPEARL_SET_JOB,
      job: job,
    };
  }

  export function failedJobLoad() {
    return {
      type: BRIGHTPEARL_FAILED_JOB_LOAD,
    };
  }
  
  export function setOrders(orders) {
    return {
      type: BRIGHTPEARL_SET_ORDERS,
      orders: orders,
    };
  }

  export function failedOrdersLoad() {
    return {
      type: BRIGHTPEARL_FAILED_ORDERS_LOAD,
    };
  }

  export function setWarehouseMappings(newMappings) {
    return {
      type: BRIGHTPEARL_SET_WAREHOUSE_MAPPING,
      warehouseMapping: newMappings,
    };
  }

  export function updateWarehouseMappings(accountCode, newMappings) {
    return async function (dispatch, getState) {
      try {
        const accountCodeMap = newMappings[accountCode];
        await saveBrightPearlWarehouseMappings(accountCode, accountCodeMap);
      } catch (err) {
      } finally{
        dispatch(loadWarehouseMappings());
      }
    };
  }



export function loadOrders(limit, page, status, sortBy, startDate = "", endDate = "", search, queryParams = '') {
  return async function (dispatch, getState) {

    try {
      const orders = await getBrightPearlOrders(
        limit,
        page,
        status,
        sortBy,
        startDate,
        endDate,
        search ?? "",
        queryParams
      );
      dispatch(setOrders(orders));
    } catch (err) {
      dispatch(failedOrdersLoad());
    }
  };



}