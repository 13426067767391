import React, { useEffect, useMemo, useState } from "react";
import { DKLabel, DKButton, showAlert, DKIcons } from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import MatchingSummaryTable from "src/pages/Magento/MatchingSummaryTable";
import customSelectTheme from "src/utils/selectTheme";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { saveDiscountCodeMapping, getMagentoDiscountCodeSearch } from "src/api";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import { DKDataGrid, INPUT_TYPE } from "deskera-ui-library";
import AsyncSelect from "react-select/async";
import { addToast } from "src/redux/actions/toasts";
import { template } from "underscore";
import { useAsyncDebounce } from "react-table";
import { loadConfig } from "src/redux/actions/magento";
import axios from "axios";
import { debounce } from "lodash";
function DiscountMapping(props) {
    const [loading, setLoading] = useState(false);
    const [hover, setHover] = useState(false);
   
    const [data, setData] = useState([
        { id: 0, deskeraDocumentCode: "", magentoDocumentCode: "",accountName: "",payload :null},
    ]
    )
    const dispatch = useDispatch();
    const magentoDiscountCodeData = useSelector((state) => state.magento.magentoDiscountCodes);
    const deskeraDiscountCodeData =useState(props.deskeraDiscountMapping)
    const [currentRow, setCurrentRow] = useState(data.length);
    const [triggered, setTriggered] = useState(false);
    const [magentoDiscountList, setMagentoDiscountList] = useState([]);
    const [deskeraDiscountList, setDeskeraDiscountList] = useState([]);
    const [deskeraIndexList, setDeskeraIndexList] = useState([]);
    const [magentoIndexList, setMagentoIndexList] = useState([])
    const [magentoSearchList, setMagentoSearchList] = useState([])
    const [input, setInput] = useState("");
    const [columns, setColumns] = useState([

        {
            Header: "Deskera Discount Code",
            accessor: "deskeraDocumentCode",
            Cell: ({ cell: { row } }) => {
                return (
                    <AsyncSelectWrapper
                        row={row}
                        loadAllOptions={loadDeskeraOptions}
                        handleChange={deskeraHandleChange}
                        customFieldData={deskeraDiscountList}
                        customFieldIndexList={deskeraIndexList}
                        type='deskeraDocumentCode'

                    />
                );
            },
        },
        {
            Header: "  Magento Discount code",
            accessor: "magentoDocumentCode",
            Cell: ({ cell: { row } }) => {
                return (
                    <AsyncSelectWrapper
                        row={row}
                        loadAllOptions={loadMagentoOptions}
                        handleChange={magentoHandleChange}
                        customFieldData={magentoDiscountList}
                        //  productData={productData}
                        customFieldIndexList={magentoIndexList}
                        type='magentoDocumentCode'
                    />
                );
            },
        },
        {
            Header: "Actions",
            accessor: "delete",
            Cell: ({ cell: { row } }) => {
                return (
                    <ButtonWrapper
                        row={row}
                        deleteRow={deleteRow}
                       
                    />
                );
            },
        }
    ]
)

useEffect(()=>{

    setColumns([

        {
            Header: "Deskera Discount Code",
            accessor: "deskeraDocumentCode",
            Cell: ({ cell: { row } }) => {
                return (
                    <AsyncSelectWrapper
                        row={row}
                        loadAllOptions={loadDeskeraOptions}
                        handleChange={deskeraHandleChange}
                        customFieldData={deskeraDiscountList}
                        customFieldIndexList={deskeraIndexList}
                        type='deskeraDocumentCode'

                    />
                );
            },
        },
        {
            Header: "  Magento Discount code",
            accessor: "magentoDocumentCode",
            Cell: ({ cell: { row } }) => {
                return (
                    <AsyncSelectWrapper
                        row={row}
                        loadAllOptions={loadMagentoOptions}
                        handleChange={magentoHandleChange}
                        customFieldData={magentoDiscountList}
                        //  productData={productData}
                        customFieldIndexList={magentoIndexList}
                        type='magentoDocumentCode'
                    />
                );
            },
        },
        {
            Header: "Actions",
            accessor: "delete",
            Cell: ({ cell: { row } }) => {
                return (
                    <ButtonWrapper
                        row={row}
                        deleteRow={deleteRow}
                       
                    />
                );
            },
        }
    ])
},[data,deskeraDiscountList,magentoDiscountList])

useEffect(()=>{
    var tmpData = []
    var tempMagentoIndexList = magentoIndexList;
    var tempDeskeraIndexList =deskeraIndexList;
    if (props.configDiscountMappings?.length>0) {
       
        props.configDiscountMappings?.forEach((field,index) => {
            const deskeraDiscountConfig = props.deskeraDiscountMapping?.find((ob) => ob.label === field.accountName)
            tempMagentoIndexList.push(field.code)
            tempDeskeraIndexList.push(deskeraDiscountConfig?.id);
            tmpData.push({
                id: index,
                deskeraDocumentCode:deskeraDiscountConfig?.id,
                magentoDocumentCode: field.code ,
                accountName :field.accountName,
                payload :field

              });
        });
        setDeskeraIndexList(tempDeskeraIndexList);
        setMagentoIndexList(tempMagentoIndexList)
        setCurrentRow(tmpData.length);
        setData(tmpData);
    }
   },[props.configDiscountMappings , magentoDiscountCodeData , props.deskeraDiscountMapping])
   
useEffect(()=>{
    var tempMagentoDiscountList = magentoDiscountList
    magentoDiscountCodeData?.data?.content?.forEach((field,index) => {

        const isDisabledList =
               props.configDiscountMappings?.filter((data, index) => {
               return data.code === field.code;
              })
              const isDisabled = isDisabledList && isDisabledList.length ? true: false;
        
       // tempDeskeraIndexList.push(field.code);
        tempMagentoDiscountList.push({
           code :field.code,
           label :field.code,
           payload :field,
           isDisabled :isDisabled
          });
    });
    setMagentoDiscountList(tempMagentoDiscountList);
    
},[props.configDiscountMappings,magentoDiscountCodeData])

useEffect(()=>{
    if(props.deskeraDiscountMapping.length>0){

        var tempDeskeraDiscountList = []
        props.deskeraDiscountMapping.forEach((field)=>{

            const isDisabledList =
               props.configDiscountMappings?.filter((data, index) => {
               return data.deskeraAccountCode === field.code;
              })
            const isDisabled = isDisabledList && isDisabledList.length ? true: false;

            tempDeskeraDiscountList.push({
                code :field.id,
                label :field.label,
                isDisabled :isDisabled,
                value :field.code
               });
        })

        setDeskeraDiscountList(tempDeskeraDiscountList);

    }
  
  
},[props.configDiscountMappings,props.deskeraDiscountMapping])
    
    const deskeraHandleChange = (e, index) => {

        var newData = data;
        var tempDeskeraIndexList = deskeraIndexList;
        var tmpDeskeraDiscountList = deskeraDiscountList;
        tempDeskeraIndexList.push(e.code);

        if (data) {
            if (newData[index].deskeraDocumentCode !== '') {
                tempDeskeraIndexList = tempDeskeraIndexList.filter((list) => list !== newData[index].deskeraDocumentCode);
                const previousDeskeraIndex = tmpDeskeraDiscountList.findIndex((deskera) => {
                    return deskera.code === newData[index].deskeraDocumentCode;
                });
                if(previousDeskeraIndex  !== -1){
                    tmpDeskeraDiscountList[previousDeskeraIndex].isDisabled = false;
                }
                }
               

            newData[index].deskeraDocumentCode = e.code;
            newData[index].accountName =e.label
            const deskeraIndex = tmpDeskeraDiscountList.findIndex((deskera) => { return deskera.code === e.code })
            tmpDeskeraDiscountList[deskeraIndex].isDisabled = true;
            setData(newData);
            setDeskeraIndexList(tempDeskeraIndexList);
            setDeskeraDiscountList(tmpDeskeraDiscountList)
        }

    }
    const magentoHandleChange = (e, index) => {

        var newData = data;
        var tempMagentoDiscountList = magentoDiscountList;
        var tempMagentoIndexList = magentoIndexList;
        tempMagentoIndexList.push(e.code);

        if (data) {
            if (newData[index].magentoDocumentCode !== '') {
                tempMagentoIndexList = tempMagentoIndexList.filter((list) => list !== newData[index].magentoDocumentCode);
                const previousMagentoIndex = tempMagentoDiscountList.findIndex((magento) => {
                    return magento.code === newData[index].magentoDocumentCode;
                });
                if(previousMagentoIndex !== -1){
                    tempMagentoDiscountList[previousMagentoIndex].isDisabled = false;
                }
                
            }
            newData[index].magentoDocumentCode = e.code;
          
            const magentoIndex = magentoDiscountList.findIndex((magento) => { return magento.code === e.code })
            tempMagentoDiscountList[magentoIndex].isDisabled = true;
            newData[index].payload = magentoDiscountList[magentoIndex].payload;
            setMagentoDiscountList(tempMagentoDiscountList);
            setData(newData);
            setMagentoIndexList(tempMagentoIndexList);
        }
    }
    const loadDeskeraOptions = async (inputValue) => {
        const response = deskeraDiscountList
        // return response
        if (inputValue === undefined || inputValue == "") {
            return response;
        } else {

            return response.filter((field) => {
                if (String(field.label)?.toLowerCase().includes(String(inputValue)?.toLowerCase())) {
                    return true;
                }
            })
        }
    }
   
  
    const loadMagentoOptions = async (inputValue) => {
      const response = magentoDiscountList;
      // return response
      if (inputValue === undefined || inputValue == "") {
        return response;
      } else {
        return debounced(inputValue, 200);
      }
    };

    const getMagentoDiscountCodeSearchCall = (inputValue) => {
      return getMagentoDiscountCodeSearch(inputValue).then((searchResponse) => {
        var tempMagentoDiscountList = [];
        searchResponse?.content?.forEach((field, index) => {
          const isDisabledList = data?.filter((data, index) => {
            return data.magentoDocumentCode === field.code;
          });
          const isDisabled = isDisabledList && isDisabledList.length ? true : false;
          tempMagentoDiscountList.push({
            code: field.code,
            label: field.code,
            isDisabled: isDisabled,
          });
        });

        return tempMagentoDiscountList;
      });
    };

    const debounced = React.useCallback(debounce(getMagentoDiscountCodeSearchCall, 200), []);

    const addRow = () => {
        var tempData = data
        tempData.push({ id: currentRow, deskeraDocumentCode: "", magentoDocumentCode: "" });
        setData(tempData)
        setCurrentRow(tempData.length);

    }
    const deleteRow = (index)=>{
        var tempData = data
        const removedRow = tempData[index];
        if (removedRow.deskeraDocumentCode !== '') {
            var tmpDeskeraDiscountList = deskeraDiscountList;
            var tempDeskeraIndexList = deskeraIndexList;
            const deskeraIndex = deskeraDiscountList.findIndex((deskera) => { return deskera.code === removedRow.deskeraDocumentCode })
            tmpDeskeraDiscountList[deskeraIndex].isDisabled = false;
            tempDeskeraIndexList = tempDeskeraIndexList.filter((list) => list !==removedRow.deskeraDocumentCode);
            setDeskeraDiscountList(tmpDeskeraDiscountList);
            setDeskeraIndexList(tempDeskeraIndexList);

        }
        if (removedRow.magentoDocumentCode !== '') {
            var tmpMagentoDiscountList = magentoDiscountList;
            var tempMagentoIndexList = magentoIndexList;
            const magentoIndex = magentoDiscountList.findIndex((magento) => { return magento.code === removedRow.magentoDocumentCode })
            tmpMagentoDiscountList[magentoIndex].isDisabled = false;
            tempMagentoIndexList = tempMagentoIndexList.filter((list) => list !== removedRow.magentoDocumentCode);
            setMagentoDiscountList(tmpMagentoDiscountList);
            setMagentoIndexList(tempMagentoIndexList);
        }

        setCurrentRow((prevState) => (
            prevState - 1
        ));
        tempData =tempData.filter((list,indexNumber)=> index !==indexNumber)
       tempData =  tempData.map((field,index)=>{
           return{
            ...field,
            id :index
           }
        })
        setData(tempData);
    }

    const validation = () => {
        const isFieldEmptyDeskerafield= data.filter((data) => {
            return data.deskeraDocumentCode === ""  ;
        });
        const isFieldEmptyMagentoField= data.filter((data) => {
            return data.magentoDocumentCode === ""  ;
        });


    
        if(data.length<=0){
            showAlert("Alert", 'Please Add atleast one row');
            return false;
        }
        if (isFieldEmptyDeskerafield.length > 0) {
            showAlert("Alert", 'Please select the Deskera code , Discount Code cannot be empty');
            return false;
        }
        if (isFieldEmptyMagentoField.length > 0) {
            showAlert("Alert", 'Please select the Magento code , Discount Code cannot be empty');
            return false;
        }


        return true;
    }

    const handleSyncSubmitAndClosePopup = async ()=>{
        handleSyncSubmit()
        if(validation()){
            props.setDiscountCodeState(false)
         }}
    const  handleSyncSubmit = async ()=>{

        if (validation()) {
            
            var tempcustomFieldList = []
            data.forEach((field) => {
                var tempMagentoDiscountCode = field.payload
                if (!tempMagentoDiscountCode.deskeraAccountCode) {
                    const deskeraDiscountConfig = props.deskeraDiscountMapping?.find((ob) => ob.label === field.accountName)
                    tempMagentoDiscountCode.deskeraAccountCode = deskeraDiscountConfig.code;
                }
                tempMagentoDiscountCode.accountName =field.accountName
                tempcustomFieldList.push(tempMagentoDiscountCode);
            });

            const payload = tempcustomFieldList
            

            try {
                const resp = await saveDiscountCodeMapping(payload);
          
                dispatch(
                  addToast({
                    type: "success",
                    title: "Success",
                    message: "Discount Code Mapping Saved successfully ",
                  })
                );
                refreshData();
                dispatch(loadConfig());
                return;
              } catch (err) {
                dispatch(
                  addToast({
                    type: "danger",
                    title: "Failure",
                    message: err.response.data.message,
                  })
                );
              }
            
          
           
        }
    }
    const handleCancel = ()=>{props.setDiscountCodeState(false)}
    const refreshData = () => {}
    const matchingDiscountScreen = () => {

        return (
            <>
                {" "}
                <div
                    className="w-100 h-100  d-flex justify-content-center  overflow-hidden"
                    style={{
                        zIndex: 99,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.4)",
                    }}
                >
                    <div
                        className=" mt-s card dk-card dk-card-shadow d-flex border border-radius-l"
                        style={{ minWidth: "100%", minHeight: "70%" }}
                    >
                        <h3 className="pt-4 ml-4">Map Discount Code</h3>
                        <div className=" overflow-hidden card-body d-flex flex-column" style={{ overflowX: "auto", height: '100%' }}>
                            <ServerPaginationTable
                                data={data ? data : []}
                                columns={columns}
                                migrate={""}
                                cart="magento"
                                refresh={refreshData}
                                tab="customFields"
                                pageCount={1}
                                loading={loading}
                                rowId="id"
                            />
                        </div>
                        <div className="ml-xl mb-l d-flex flex-row">

                            <button
                                className="btn border-radius-m p-v-s text-white bg-success"
                                onClick={() => { addRow() }}
                                disabled={triggered}
                                onMouseOver={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >
                                <span className="svg-icon svg-baseline mr-2 svg-white">
                                    {triggered ? (
                                        <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                {
                                    <span>
                                        + Add Discount Code
                                    </span>
                                }
                            </button>
                            
                        </div>
                        <div className=" mb-l d-flex flex-row-reverse" style={{ marginTop: "30px" }}>
                            <DKButton
                                className="bg-success px-4 text-white"
                                onClick={handleSyncSubmitAndClosePopup}
                                title={"SAVE AND CLOSE"}
                                style={{ marginLeft: "10px", marginRight: "10px" }}
                            ></DKButton>
                            <DKButton
                                className="bg-success px-4 text-white"
                                onClick={handleSyncSubmit}
                                title={"SAVE"}
                                style={{ marginLeft: "10px", marginRight: "10px" }}
                            ></DKButton>
                            <button
                                className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
                                onClick={handleCancel}
                            >
                                <span className="svg-icon svg-baseline mr-2 svg-disabled">
                                    <CloseIcon />
                                </span>
                                <span>Cancel</span>
                            </button>
                        </div>

                    </div>
                </div>{" "}
            </>
        );
    }
    return (
        <>
            <div className="d-flex">
                <div
                    className="vw-100 vh-100 d-flex justify-content-center align-items-center"
                    style={{
                        zIndex: 99,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        background: "rgba(0, 0, 0, 0.4)",
                        width: 600,
                    }}
                >
                    <div
                        className="card dk-card dk-card-shadow d-flex border border-radius-l"
                        style={{ minWidth: "60%", minHeight: "85%" }}
                    >
                        <div className="card-body d-flex flex-column" style={{ align: "center" }}>
                            <div className=" card-body d-flex flex-column">
                                <div class="overflow-hidden flex-row">{matchingDiscountScreen()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DiscountMapping;


function AsyncSelectWrapper({ row, loadAllOptions, handleChange, customFieldData, customFieldIndexList, type }) {
    const [async, setAsync] = useState(
        type === 'deskeraDocumentCode'
            ? customFieldData.find((field) => field.code === row.original.deskeraDocumentCode)
            : customFieldData.find((field) => field.code === row.original.magentoDocumentCode)
    );

    return (
        <div className="  justify-content-start align-items-start mr-xxl">
            <AsyncSelect
                placeholder={'Select Discount Code'}
                className="filter-selector ml-3"
                cacheOptions
                defaultOptions
                maxMenuHeight={200}
                maxMenuWidth={100}
                value={async}
                getOptionLabel={(e) => {
                    return e.label
                }}
                getOptionValue={(e) => {
                    return e.code
                }}
                loadOptions={loadAllOptions}
                onChange={(e) => {
                    setAsync(e);
                    handleChange(e, row.index);
                }}
            />
        </div>
    );
}

function ButtonWrapper({ row, deleteRow }) {

    return <>
        <div className="ml-l">
            <DKButton
                className=" border-l text-white"
                icon={DKIcons.ic_delete}
                onClick={() => { deleteRow(row.index) }}
                style={{
                    border: '1px'
                }}
                title=""
            ></DKButton>
        </div></>

}
