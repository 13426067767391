import React, { useEffect, useState } from "react";
import SearchLogo from "src/assets/Search";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
} from "react-table";
import clsx from "clsx";
import UpArrowIcon from "src/assets/UpArrow";
import ForwardRight from "src/assets/ForwardRight";
import ArrowRight from "src/assets/ArrowRight";
import Select from "react-select";
import customSelectTheme from "src/utils/selectTheme";
import EmptyTray from "src/assets/EmptyTray";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "src/redux/actions/toasts";
import ArrowClockwise from "src/assets/ArrowClockwise";
import { getDeskeraTenantDetails } from "src/api";
import Moment, { suppressDeprecationWarnings } from "moment";
import { startDateFormat } from "src/utils/Constants";
import { DKButton, DKStatus } from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { Checkbox } from "./Checkbox";
import ProductSyncConfig from "src/pages/Amazon/ProductSyncConfig";
import { SearchFilter } from "./Filters/SearchFilter";
import SearchBar from "./Filters/SearchBar";
import SelectBox from "./Filters/SelectBox";
import SyncByDateFilter from "./SyncByDateFilter";

function AmazonTable({
  data,
  columns,
  migrate,
  cart,
  refresh,
  syncButton,
  minHeight,
  tab,
  pageCount: controlledPageCount,
  loading,
  rowId,
  onFilter,
}) {
  const [value, setValue] = useState();
  const [hover, setHover] = useState(false);
  const tabb = tab;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [options, setOptions] = useState();
  const [syncConfigOpen, setSyncConfigOpen] = useState(false);
  const [selectiveSync, setSelectiveSync] = useState(false);
  const job = useSelector((state) => state[cart].job.data);
  const jobRunning =
    job && (job.jobStatus === "QUEUED" || job.jobStatus === "IN_PROGRESS") ? true : false;
  const [triggered, setTriggered] = useState(false);
  const dispatch = useDispatch();
  const [showMatchingSummary, setShowMatchingSummary] = useState(false);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      manualSortBy: true,
      autoResetSelectedRows: false,
      getRowId: (row) => row[rowId],
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",

          Header: ({
            getToggleAllPageRowsSelectedProps,
            toggleRowSelected,
            rows,
            selectedFlatRows,
          }) => {
            const { onChange, style, ...otherProps } = getToggleAllPageRowsSelectedProps();
            const getSelectableRows = rows.filter(
              (row) => !(row.original.syncStatus === "SUCCESSFUL")
            );

            // Override onChange
            const onChangeOverride = (event) => {
              getSelectableRows.forEach((row) =>
                toggleRowSelected(row.id, event.currentTarget.checked)
              );
            };

            const checked =
              getSelectableRows.length == selectedFlatRows.length && getSelectableRows.length > 0;
            const indeterminate = selectedFlatRows.length > 0 && !checked;
            const disabled = getSelectableRows.length == 0;
            const newProps = {
              onChange: onChangeOverride,
              style: style,
              checked: checked,
              indeterminate: indeterminate,
              disabled: disabled,
            };
            return <Checkbox {...newProps} />;
          },

          Cell: ({ row }) => (
            <div>
              {
                <Checkbox
                  {...row.getToggleRowSelectedProps()}
                  disabled={row.original.syncStatus === "SUCCESSFUL"}
                />
              }
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  function refreshData() {
    refresh({ pageIndex, pageSize, sortBy });
  }
  function updateOptions() {
    const products = data;
    const productsOptions = [];
    products.forEach((product) => {
      productsOptions.push({ value: product[2], label: product[2] });
    });
    setOptions(productsOptions);
  }

  useEffect(() => {
    updateOptions();
  }, []);

  function amazonSyncConfig() {
    return (
      <SyncByDateFilter
        selected={startDate}
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        isInline={true}
        selectsRange={true}
        syncSubmit={(e) => {
          handleSyncSubmit();
        }}
        onClose={(e) => {
          setSyncConfigOpen(false);
        }}
      />
    );
  }

  const colummsLength = columns.length;
  const dataLength = data.length;
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleChange(e) {
    e.preventDefault();
    setValue(e.target.value);
    onChange(e.target.value);
  }

  const pageSizeOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  const statusOptions = [
    {
      value: "SUCCESSFUL",
      label: "Successful",
    },
    {
      value: "FAILED",
      label: "Failed",
    },
    {
      value: "PENDING",
      label: "Pending",
    },
  ];

  useEffect(() => {
    refresh({ pageIndex, pageSize, sortBy });
  }, [pageIndex, pageSize, sortBy]);

  async function handleSyncSubmit() {
    setTriggered(true);
    var bbd;
    try {
      const tenantDetails = await getDeskeraTenantDetails();
      bbd = tenantDetails.bookBeginningStartDate;
    } catch (err) {
      setTriggered(false);
      return;
    }

    let requestBody = {
      endDate: endDate,
      startDate: startDate,
    };

    if (!endDate || !startDate) {
      return;
    }

    let fromDate = new Date(startDate);
    let toDate = new Date(endDate);
    let msDay = 1000 * 60 * 60 * 24;
    let diffMs = Math.abs(toDate - fromDate);
    let diffDays = diffMs / msDay;

    if (diffDays > 30) {
      setTriggered(false);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Please sync a maximum of 30 days data at once",
        })
      );
      return;
    }

    try {
      setTriggered(true);
      setSyncConfigOpen(false);
      const ids = Object.entries(selectedRowIds).map((k) => k[0]);
      requestBody = { ...requestBody, amazonOrderIds: ids };
      const resp = await migrate(requestBody);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job started",
        })
      );
    } catch (err) {
      if (cart !== "amazon") {
        dispatch(
          addToast({
            type: "danger",
            title: "Failure",
            message: err.response.data.message,
          })
        );
      }
    }
    refresh({ pageIndex, pageSize });
    setTriggered(false);
  }

  function Warning() {
    return <div className="bg-chip-blue border-radius-l p-h-s ml-s p-v-xs">Syncing...</div>;
  }

  return (
    <>
      {syncConfigOpen && amazonSyncConfig()}
      {showMatchingSummary && (
        <>
          <ProductSyncConfig setSyncConfigOpen={setShowMatchingSummary} refreshData={refreshData} />
        </>
      )}
      <div className="d-flex flex-row mb-3 space-between align-items-center justify-content-between">
        <div style={{ width: "200px" }}>
          <button
            className="btn p-v-s text-white bg-success border-radius-m"
            style={{
              margin: "2px 10px",
            }}
            onClick={() => refresh({ pageIndex, pageSize })}
          >
            <span className="svg-icon">
              <ArrowClockwise />
            </span>
          </button>
          {tabb === "products" && (
            <button
              className="btn border-radius-m p-v-s text-white bg-primary"
              onClick={() => {
                setShowMatchingSummary(true);
              }}
              style={{ margin: "2px 10px" }}
            >
              Map Products
            </button>
          )}
          {Object.entries(selectedRowIds).map((k) => k[0]).length == 0 && syncButton && (
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setSyncConfigOpen(true)}
              disabled={triggered}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                {triggered ? (
                  <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                ) : (
                  <></>
                )}
              </span>
              <span>Sync Orders</span>
            </button>
          )}
          {Object.entries(selectedRowIds).map((k) => k[0]).length > 0 && syncButton && (
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => handleSyncSubmit()}
              disabled={triggered}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                {triggered ? (
                  <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                ) : (
                  <></>
                )}
              </span>
              <span>Sync Orders</span>
            </button>
          )}
        </div>
        <div style={{ verticalAlign: "center", marginTop: "5px" }}>
          {triggered && hover && Warning()}
        </div>
        {tab === "products" || tab === "orders" ? (
          <SearchFilter
            style={{ display: "flex", justifyContent: "end", width: "40rem" }}
            onFilter={(query) => {
              if (tab === "orders") {
                onFilter({ pageSize, pageIndex, sortBy, query });
              } else {
                onFilter(query);
              }
            }}
          >
            <SearchBar
              placeholder={"Search Products"}
              inputStyle={{ width: "12rem" }}
              style={{ minWidth: "15rem", margin: "2px" }}
              queryKey={"search"}
            />
            <SelectBox
              placeholder={"Filter by status"}
              queryKey={"status"}
              customSelectTheme={customSelectTheme}
              style={{ minWidth: "15rem", margin: "2px" }}
              options={statusOptions}
              isMulti={true}
            />
          </SearchFilter>
        ) : (
          <div style={{ marginLeft: "auto" }}>
            <form className="form-inline">
              <div className="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-light">
                    <span className="svg-icon svg-disabled">
                      <SearchLogo />
                    </span>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleChange}
                  value={value}
                />
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="card dk-card" style={{ overflowX: "auto" }}>
        <table
          style={{ minHeight: minHeight ?? "auto" }}
          className="table m-0 dk-table-hover dk-table"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.className },
                      column.getSortByToggleProps(),
                    ])}
                    style={{ width: `${column.width ?? "auto"}` }}
                  >
                    <span className="fs-r text-align-left fw-m fs-m text-gray cursor-hand">
                      {column.render("Header")}
                    </span>
                    <span
                      className={clsx(
                        "svg-icon svg-disabled svg-baseline",
                        !column.isSorted && "invisible",
                        column.isSorted && column.isSortedDesc && "svg-flipped"
                      )}
                    >
                      <UpArrowIcon />
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {dataLength === 0 && (
              <tr>
                <td colSpan={colummsLength}>
                  <div className="d-flex flex-column align-items-center">
                    <span className="mt-3 svg-disabled" style={{ width: "50px", height: "50px" }}>
                      <EmptyTray />
                    </span>
                    <span className="mt-2 text-muted">No records were found.</span>
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps([{ className: cell.column.className }])}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
          {loading && <label className="d-flex justify-content-center">Loading...</label>}
        </table>
      </div>
      <div className="d-flex flex-row-reverse align-items-center mt-3">
        <div className="d-flex flex-row align-items-center">
          <span className="mr-2">Max rows per page:</span>
          <Select
            placeholder="Page"
            className="page-selector"
            options={pageSizeOptions}
            value={pageSizeOptions.filter((option) => option.value === pageSize)}
            menuPlacement="auto"
            onChange={(e) => {
              setPageSize(Number(e.value));
            }}
            theme={customSelectTheme}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
          />
        </div>
        <div className="d-flex flex-row align-items-center mr-2">
          <button className="btn mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
          <button className="btn mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <span className="mr-2">
            {pageIndex + 1} / {Math.max(pageOptions.length,1)}
          </span>
          <button className="btn mr-2" onClick={() => nextPage()} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ArrowRight />
            </span>
          </button>
          <button className="btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ForwardRight />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default AmazonTable;
